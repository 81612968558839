import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";

// project imports
import { ThemeMode } from "config";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IoSearchCircleOutline } from "react-icons/io5";
import { ClearIcon } from "@mui/x-date-pickers";
import { useState } from "react";
// ==============================|| PACKAGES -  DISCOUNT EDIT ||============================== //

const EditDiscount = ({ onHandleDiscount , formik}) => {
  const theme = useTheme();

  const handleDiscountEdit = (event) => {
    formik.setFieldValue('discount',  event.target.value);
    onHandleDiscount(event.target.value, formik.values.discountedMode);
  };

  const handleUnitChange = (event) => {
    formik.setFieldValue('discountedMode',  event.target.value) 
    onHandleDiscount(formik.values.discount ,event.target.value);
  };

  return (
    // <OutlinedInput
    //   value={discount?.discountMode}
    //   placeholder="Search..."
    //   onChange={handleDiscountEdit}
    //   sx={{
    //     mb: 1.5,
    //     // "& input:focus": {
    //     //   bgcolor:
    //     //     theme.palette.mode === ThemeMode.DARK ? "dark.800" : "grey.50",
    //     //  },
    //     // "& input:hover": {
    //     //   bgcolor:
    //     //     theme.palette.mode === ThemeMode.DARK ? "dark.800" : "grey.50",
    //     // },
    //     // "& input:hover + fieldset": {
    //     //   display: "block",
    //     // },
    //     // "&, & input": { bgcolor: "transparent" },
    //     // "& fieldset": { display: "none" },
    //     // "& input:focus + fieldset": { display: "block" },
    //   }}
    //   endAdornment={
    //     <InputAdornment position="end">
    //       <Select
    //         labelId="unit-label"
    //         value={discount.unit}
    //         onChange={handleUnitChange}
    //         // sx={{
    //         //   border: "none",
    //         //   ".MuiOutlinedInput-notchedOutline": {
    //         //     border: "none", // Removes the border from the Select box
    //         //   },
    //         //   "&:hover .MuiOutlinedInput-notchedOutline": {
    //         //     border: "none", // Removes the border on hover
    //         //   },
    //         //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //         //     border: "none", // Removes the border when focused
    //         //   },
    //         // }}
    //       >
    //         <MenuItem value="percentage">
    //           <PercentIcon />
    //         </MenuItem>
    //         <MenuItem value="fixed">
    //           <CurrencyRupeeIcon />
    //         </MenuItem>
    //       </Select>
    //     </InputAdornment>
    //   }
    // />

    <Grid item xs={12} className="flex-wraps" justifyContent="flex-end" alignItems="flex-end">
        <OutlinedInput
          // value={discount?.discountMode}
          value={formik?.values?.discount}
          onChange={handleDiscountEdit}
          sx={{
             ".MuiOutlinedInput-input":{
              padding: "5px 2px",
              /* padding-left: 10px, */
              width: "50px",
              textAlign: "center",
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <Select
                labelId="unit-label"
                className="slect-package-discount"
                // value={discount.unit}
                value={formik?.values?.discountedMode}
                onChange={handleUnitChange}
                sx={{
                  ".MuiOutlinedInput-input":{
                    height:"20px",
                    width: "50px",
                  },
                  ".MuiOutlinedInput-notchedOutline": {
                    border: "none", // Removes the border from the Select box
                    fontSize:"12px",
                    fontWeight:300,
                    
                  },
                //   "&:hover .MuiOutlinedInput-notchedOutline": {
                //     border: "none", // Removes the border on hover
                //   },
                //   "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                //     border: "none", // Removes the border when focused
                //   },
                }}
              >
                <MenuItem value="percentage">
                  <PercentIcon />
                </MenuItem>
                <MenuItem value="fixed">
                  <CurrencyRupeeIcon />
                </MenuItem>
              </Select>
            </InputAdornment>
          }
        />
      </Grid>
    // <OutlinedInput
    //     fullWidth
    //     //value={packageData.title || 'New Package'}
    //     onChange={handleDiscountEdit}
    //     sx={{
    //         mb: 1.5,
    //         '& input:focus': {
    //             bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.800' : 'grey.50'
    //         },
    //         '& input:hover': {
    //             bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.800' : 'grey.50'
    //         },
    //         '& input:hover + fieldset': {
    //             display: 'block'
    //         },
    //         '&, & input': { bgcolor: 'transparent' },
    //         '& fieldset': { display: 'none' },
    //         '& input:focus + fieldset': { display: 'block' }
    //     }}
    //     placeholder="title"
    //     InputProps={{
    //         endAdornment: (
    //             <InputAdornment position="end">
    //                 <FormControl>
    //                     <InputLabel id="unit-label"></InputLabel>
    //                     <Select
    //                         labelId="unit-label"
    //                         //value={validity.unit}
    //                         onChange={handleUnitChange}
    //                         sx={{
    //                             border: 'none',
    //                             '.MuiOutlinedInput-notchedOutline': {
    //                               border: 'none', // Removes the border from the Select box
    //                             },
    //                             '&:hover .MuiOutlinedInput-notchedOutline': {
    //                               border: 'none', // Removes the border on hover
    //                             },
    //                             '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //                               border: 'none', // Removes the border when focused
    //                             },
    //                           }}
    //                     >
    //                         <MenuItem value="percentage"><PercentIcon /></MenuItem>
    //                         <MenuItem value="fixed"><CurrencyRupeeIcon /></MenuItem>
    //                     </Select>
    //                 </FormControl>
    //             </InputAdornment>
    //         ),
    //     }}
    // />
  );
};

EditDiscount.propTypes = {
  onHandleDiscount:PropTypes.func
};

export default EditDiscount;
