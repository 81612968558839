// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import FullScreenSection from './FullScreenSection';
import NotificationSection from './NotificationSection';

import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';
import { MenuOrientation, ThemeMode } from 'config';

// assets
import { IconMenu2 } from '@tabler/icons-react';
import { Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useCountyPrice } from 'components/hooks/useCountyPrice';
// import { InvoiceSection } from './invoiceSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const downMD = useMediaQuery(theme.breakpoints.down('md'));

    const { mode, menuOrientation } = useConfig();
    const { menuMaster } = useGetMenuMaster();
    const drawerOpen = menuMaster.isDashboardDrawerOpened;
    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downMD;
    const {invoiceSales} = useSelector((state)=> state.invoice)
    const [totalSale, setTotalSales] = useState({
        todaysSale:0,
        yesterdaySale:0
    });

    const { format } = useCountyPrice();
    let data = { 
        todaysSale:0,
        yesterdaySale:0
    }
    useEffect(() => {
        const today = dayjs().startOf('day'); // Reset the time to midnight
        invoiceSales?.map((i)=>{ 
            if(today.isSame(dayjs(i?.invoiceDate), 'day')){
                data["todaysSale"] = i?.netPrice;
            }else if(dayjs(i?.invoiceDate).isSame(dayjs().subtract(1, 'day'), 'day')){
                data["yesterdaySale"] = i?.netPrice;
            }
            setTotalSales(data);
        })
    }, [invoiceSales])

    return (
        <>
            {/* logo & toggler button */}
            <Box sx={{ width: downMD ? 'auto' : 228, display: 'flex' }}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {!isHorizontal && (
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'secondary.light',
                            color: mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                            '&:hover': {
                                bgcolor: mode === ThemeMode.DARK ? 'secondary.main' : 'secondary.dark',
                                color: mode === ThemeMode.DARK ? 'secondary.light' : 'secondary.light'
                            }
                        }}
                        onClick={() => handlerDrawerOpen(!drawerOpen)}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="20px" />
                    </Avatar>
                )}
            </Box>

            {/* header search */}
            <SearchSection />
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}
            {/* <Box>
                <InvoiceSection />
            </Box> */}

            <Chip label={`Yesterday Total Sales: ${format(totalSale.yesterdaySale)}`} color="primary" size="large" variant="filled"  
                sx={{
                    ...theme.typography.commonAvatar,
                    transition: 'all .2s ease-in-out',
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'secondary.light',
                    color: theme.palette.mode === ThemeMode.DARK ? 'warning.dark' : 'secondary.dark',
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'warning.dark' : 'secondary.dark',
                        color: theme.palette.mode === ThemeMode.DARK ? 'grey.800' : 'secondary.light'
                    },
                    marginRight:"15px"
            }}/>

            <Chip label={`Todays Total Sales: ${format(totalSale.todaysSale)}`} color="primary" size="large" variant="filled"  
                sx={{
                    ...theme.typography.commonAvatar,
                    transition: 'all .2s ease-in-out',
                    bgcolor: theme.palette.mode === ThemeMode.DARK ? 'dark.main' : 'secondary.light',
                    color: theme.palette.mode === ThemeMode.DARK ? 'warning.dark' : 'secondary.dark',
                    '&[aria-controls="menu-list-grow"],&:hover': {
                        bgcolor: theme.palette.mode === ThemeMode.DARK ? 'warning.dark' : 'secondary.dark',
                        color: theme.palette.mode === ThemeMode.DARK ? 'grey.800' : 'secondary.light'
                    }
            }}/>
           
            {/* notification */}
            {/* <NotificationSection /> */}

            {/* full sceen toggler */}
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <FullScreenSection />
            </Box>

            {/* profile */}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};

export default Header;
