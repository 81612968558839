import React, { useEffect, useMemo, useState } from "react";
// material-ui
import { Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Toolbar, Tooltip, Typography, alpha, capitalize, styled } from '@mui/material'
import { FormattedMessage } from 'react-intl'

// assets
import { AddCategoryForm } from "../AddCategoryForm";
import {
  addCategory,
  deleteCategory,
  getCategory,
  updateCategory,
} from "store/catalogue/slice";
import imageEmpty from "assets/images/maintenance/service-amico.svg";
import { ThemeMode } from "config";
import { useTheme } from "@mui/material/styles";
// material-ui

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// project imports
import MainCard from 'ui-component/cards/MainCard';
// assets
import { useCompanyData } from 'components/hooks/useCompanyData';
import { v4 as uuid } from 'uuid'
import { dispatch, useAppSelector as useSelector } from 'store';
import Loader from 'ui-component/Loader';
import AddIcon from '@mui/icons-material/Add';
import { fetchEmployeeServiceRelationData } from 'store/staff/action';
import ClearIcon from '@mui/icons-material/Clear';
import { Row } from '../Row';
import SearchIcon from "@mui/icons-material/Search";
import EmptyView from "components/empty-view/EmptyView";
import CatalogueSkeleton from "ui-component/cards/Skeleton/Catalogue";
import AlertServiceDelete from "pages/users/employees/AlertServiceUnassign";

const Services = () => {
    const [openModalCategory, setOpenModalCategory] = useState(false);
    const [editData, setEditData] = React.useState(null);
    const [deleteConfirm, setDeleteConfirm] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const serviceState = useSelector((state) => state.catalogue);
    const [serviceList ,setServiceList] = React.useState(serviceState.serviceList || []);
    const [search , setSearch] =React.useState('');
    const companyId = useCompanyData();

    let newRow = [];

    // useEffect(() => {
    //     setLoading(true);
    //     dispatch(getCategory(companyId)).then(()=>{
    //         setLoading(false);
    //     });
    // }, [])

    useEffect(()=>{
        setServiceList(serviceState.serviceList);
    },[serviceState.serviceList])

    const handleToggleAddCategoryDialog = () => {
        setEditData(false);
        setOpenModalCategory(!openModalCategory);
    };

    const handleCategoryCreate = async (data) => {
        setLoading(true);
        try {
            if (editData) {
                dispatch(
                    updateCategory({
                        body: data,
                        id: editData?.id,
                    })
                ).then(() => {
                    setLoading(false);
                    setOpenModalCategory(false)
                })
            } else {
                dispatch(addCategory(data)).then(() => {
                    setLoading(false);
                    setOpenModalCategory(false);
                });
            }
            
        } catch (err) {
            console.log("err", err);
        }
    };

    const onHandleDelete = (data) => {
        setLoading(true);
        dispatch(deleteCategory({ id: data?.id, companyId: companyId })).then(() => {
            setDeleteConfirm(null)
        }).then(() => {
            setLoading(false);
        });
    }
    const handleSearch = (searchValue) => {
      setSearch(searchValue);
      if (searchValue === "") {
        setServiceList(serviceState.serviceList);
      }else{
        const filteredServices = serviceList.map(category => ({
          ...category,
          svcCtlgItems: category.svcCtlgItems.filter(service =>
              service.title.toLowerCase().includes(searchValue.toLowerCase())
          )
        })).filter(category => category.svcCtlgItems.length > 0);
        if(filteredServices?.length > 0){
          setServiceList(filteredServices)
        }else{
          setServiceList([]);
        }
      }
    }

     if (serviceState.isFetching) return <CatalogueSkeleton />;

  return (
    <>
     {/* {serviceState.isFetching && ( <CatalogueSkeleton /> ) } */}
      <Grid item xs={12}>
        <MainCard content={false} title="">
          {/* table */}
          <TableContainer >
            <Table aria-label="collapsible table" size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ pl: 3 }}>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={handleToggleAddCategoryDialog}
                        endIcon={<AddIcon />}
                      >
                        ADD
                      </Button>
                  </TableCell>
                  <TableCell>Title &nbsp; (Total Service)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ pr: 3 }} align="right">
                    <TextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon
                              fontSize="small"
                              // onClick={handleSearch}
                              cursor="pointer"
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        handleSearch(e?.target.value)
                      }}
                      placeholder="Search Service(s)"
                      value={search}
                      size="small"
                      sx={{ width: { xs: 1, sm: "auto" } 
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>

             
              <TableBody>
                 { serviceList.length > 0 ?  serviceList.map((row) => (
                    <Row
                      key={uuid()}
                      row={row}
                      onClickEditData={(data) => {
                        setEditData(data);
                        setOpenModalCategory(true);
                      }}
                      onDeleteData={(data) => {
                        setDeleteConfirm(data);
                      }}
                    />
                  )):
                  (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                          <EmptyView
                            message1={`There is no service `}
                            message2={"When you have services, they will display here"}
                            imgDark={imageEmpty}
                          />
                      </TableCell>
                    </TableRow>
                  )}
              
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Grid>   
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => setOpenModalCategory(false)}
        open={openModalCategory}
        sx={{ "& .MuiDialog-paper": { p: 0 } }}
      >
        {openModalCategory && (
          <AddCategoryForm
            onCancel={() => {
              setOpenModalCategory(false);
              setEditData(null);
            }}
            onAddCategory={handleCategoryCreate}
            editableData={editData}
          />
        )}
      </Dialog>
      {deleteConfirm && <AlertServiceDelete title="The following item(s) will be deleted." open={deleteConfirm} handleClose={()=>setDeleteConfirm(null)} item={deleteConfirm} handleSubmit={onHandleDelete} />}
    </>
  );
};

export default Services;
