// third-party
import { createSlice , current } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { ENDPOINTS } from 'network/ENDPOINT';
import dayjs from 'dayjs';
import { serviceTabsValue } from 'utils/constantString';
import { openSnackbar } from 'store/snackbar/slice';
import { assignServices, removeEmployeeServiceRelation, updateEmployeServices } from 'store/employees/slice';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    categoryList: [],
    serviceList: [],
    productList: [],
    serviceListExcludingCatalogue:[],
    expandedCategory: '',
    serviceEmployees: {},
    isFetching: false,
    packagesList:[]
};

const slice = createSlice({
    name: 'catalogue',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;

        },

        // GET CATEGORY
        getCategorySuccess(state, action) {
            const tempCatalogue = action.payload;
            let category = [];
            let products = [];
            let services = [];
            tempCatalogue?.map((item) => {
                category.push(item);
                if (item?.cateType == serviceTabsValue?.service) {
                    services.push(item);
                } else if (item?.cateType == serviceTabsValue?.product) {
                    products.push({...item, svcCtlgItems:item?.productCatalogues});
                } else {
                }
            });

            const servicesMap = new Map();
            const servicesOnly = services.flatMap(category => category.svcCtlgItems);
            servicesOnly.forEach(service => {
                const { employeeRelation, id } = service;
                if (!servicesMap.has(id)) {
                    servicesMap.set(id, employeeRelation)
                }
            })
            state.categoryList = category;
            state.productList = products;
            state.serviceList = services;
            state.serviceEmployees = servicesMap;
            state.serviceListExcludingCatalogue = servicesOnly;

        },

        // ADD CATEGORY
        addCategorySuccess(state, action) {
            let list;
            if (action.payload.cateType === 'product') {
                list = state.productList
            } else {
                list = state.serviceList
            }
            const serviceData = [{ ...action.payload, svcCtlgItems: [] }, ...list];
            action.payload.cateType === 'product' ?
                state.productList = serviceData
                : state.serviceList = serviceData;

        },

        // UPDATE CATEGORY
        updateCategorySuccess(state, action) {
            let list;
            if (action.payload.cateType === 'product') {
                list = state.productList
            } else {
                list = state.serviceList
            }
            const serviceUpdatedData = list?.map((item, key) => {
                if (action.payload?.id == item?.id) {
                    return { ...action.payload, svcCtlgItems: item?.svcCtlgItems };
                }
                return item;
            });
            action.payload.cateType === 'product' ?
                state.productList = serviceUpdatedData
                : state.serviceList = serviceUpdatedData;


        },

        // REMOVE CATEGORY
        removeCategorySuccess(state, action) {
            let list;
            const isProduct = state.productList.some(c => c.id === action.payload);
            if (isProduct) {
                list = state.productList
            } else {
                list = state.serviceList
            }
            const tempUpdatedList = list?.filter((i) => i?.id !== action.payload);
            isProduct ? state.productList = tempUpdatedList : state.serviceList = tempUpdatedList;
        },

        // ADD SERVICE
        addServiceSuccess(state, action) {
            let list;
            const isProduct = state.productList.some(c => c.id === action.payload.catalogueId);
            if (isProduct) {
                list = state.productList
            } else {
                list = state.serviceList
            }

            const serviceUpdatedData = list?.map((item, key) => {
                if (action.payload.catalogueId == item?.id) {
                    let temp = item?.svcCtlgItems;
                    return {
                        ...item,
                        svcCtlgItems: [...temp, action.payload],
                    };
                }
                return item;
            });
            isProduct ? state.productList = serviceUpdatedData : state.serviceList = serviceUpdatedData;
        },

        // UPDATE SERVICE
        updateServiceSuccess(state, action) {
            let list;

            const isProduct = state.productList.some(c => c.id === action.payload.catalogueId);
            if (isProduct) {
                list = state.productList
            } else {
                list = state.serviceList
            }
            const serviceUpdatedData = list?.map((item, key) => {
                if (action.payload.catalogueId == item?.id) {
                    const result = item.svcCtlgItems.find(item => item.id === action.payload.id);
                    if(result){
                        return {
                            ...item,
                            svcCtlgItems: item.svcCtlgItems.map(
                                (i) => {
                                    if (i.id === action.payload.id) {
                                        return { ...i, ...action.payload };
                                    }
                                    return i;
                                }
                            ),
                        };
                    }else{
                        return {...item, svcCtlgItems:[...item?.svcCtlgItems, action.payload] }
                    }
                }
                return item;
            });
            isProduct ? state.productList = serviceUpdatedData : state.serviceList = serviceUpdatedData;
        },

        // REMOVE SERVICE
        removeServiceSuccess(state, action) {
            let list;
            const isProduct = state.productList.some(c => c.id === action.payload.catalogueId);
            if (isProduct) {
                list = state.productList
            } else {
                list = state.serviceList
            }
            const serviceUpdatedData = list?.map((item, key) => {
                if (action.payload.catalogueId == item?.id) {
                    return {
                        ...item,
                        svcCtlgItems: item?.svcCtlgItems?.filter((i) => {
                            return i?.id != action.payload?.id;
                        }),
                    };
                }
                return item;
            });
            isProduct ? state.productList = serviceUpdatedData : state.serviceList = serviceUpdatedData;
        },

        setExpandedCategory(state, action) {
            state.expandedCategory = action.payload;
        },
        //ASSIGN SERVICE SUCCESS


        assignServiceSuccess(state, action) {
            const newServiceEmployeesMap = new Map(state.serviceEmployees);
            const serviceIds = action.payload.serviceIds;
            for (let id_index in serviceIds) {
                const id = serviceIds[id_index];
                const newEmployee = {
                    "svcCtlgItemsId": id,
                    "employeeId": action.payload.id,
                    "employee": {
                        "id": action.payload.id,
                        "firstName": action.payload.firstName,
                        "lastName": action.payload.lastName,
                        "email": action.payload.email,
                        "phoneNumber": action.payload.phoneNumber
                    }
                }
                const existingItems = newServiceEmployeesMap.get(id) || [];
                newServiceEmployeesMap.set(id, [...existingItems, newEmployee]);
            }
            //console.log("newEmployees" , newServiceEmployeesMap)
            state.serviceEmployees = newServiceEmployeesMap;

        },


        //SET ISFETCHING
        setIsFetching(state, action) {
            state.isFetching = action.payload
        },

    // REMOVE PACKAGE
    removePackageSuccess(state, action) {
        const tempPackageList = state.packagesList?.filter((i) => i?.id !== action.payload?.id);
            state.packagesList=tempPackageList;     
    },



        //REMOVE SERVICE EMPLOYEE  RELATION
        removeServiceEmployeeRelationSuccess(state, action){
            const serviceId = action.payload.svcCtlgItems.id;
            const newRelationMap = new Map(state.serviceEmployees); 
            const currentServiceRelation = newRelationMap.get(serviceId)
            if(currentServiceRelation){
                const updatedRelation = currentServiceRelation.filter(se=>se.id!=action.payload.id)
                newRelationMap.set(action.payload.svcCtlgItems.id,updatedRelation)
            }
            state.serviceEmployees =  newRelationMap
        },

        getPackageSuccess(state, action){
            state.packagesList = action.payload?.packages;
        },

        addPackageSuccess(state, action){
            state.packagesList = [...state.packagesList,action.payload];
        },

        updatePackageSuccess(state, action){
            const updatePackageData = state.packagesList?.map((item)=>{
               if(item?.id === action?.payload?.id){
                    return action?.payload
               }
               return item
            })
            console.log("updatePackageData",updatePackageData)
            state.packagesList = updatePackageData
        },

        // deletePackageSuccess(state, action){
        //     const updatePackageData = state.packagesList?.filter((i)=> i?.id !== action?.payload?.id)
        //     state.packagesList = updatePackageData
        // }
        
    }
});

// Reducer
export default slice.reducer;


export const selectServiceList = (state) => state.catalogue.serviceList;


// ----------------------------------------------------------------------

export function initializeExpandCategory() {
    dispatch(slice.actions.setExpandedCategory(null));
}


export function getCategory(id) {
    return async () => {
        try {

            const getAllServices = axios.get(`${ENDPOINTS.GET_SERVICE_API}?companyId=${id}&type=service&isAssignedStaffRequired=true`);
            const getAllProducts = axios.get(`${ENDPOINTS.GET_SERVICE_API}?companyId=${id}&type=product`);
            dispatch(slice.actions.setIsFetching(true))
            Promise.all([getAllServices, getAllProducts])
                .then((results) => {
                    dispatch(slice.actions.setIsFetching(false))
                    const services = results[0]?.data.data.serviceCatalogue || [];
                    const products = results[1]?.data?.data.serviceCatalogue || [];
                    dispatch(slice.actions.getCategorySuccess([...services, ...products]))
                    //console.log(dispatch(slice.actions.getServiceList()))
                    //console.log("slice.selectors.selectserviceList" , slice.getSelectors((state) => console.log(state)))
                });
            //    const response = await axios.get(`${ENDPOINTS.GET_SERVICE_API}?companyId=${id}`);
            //    dispatch(slice.actions.getCategorySuccess(response?.data?.data))
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addCategory(body) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.ADD_CATEGORY_API, body);
            dispatch(slice.actions.addCategorySuccess(response.data?.data));
            dispatch(openSnackbar({
                open: true,
                message: 'The new category has been created successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
            dispatch(slice.actions.hasError(false));
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.error || error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        }
    };
}

export function updateCategory({ id, body }) {
    return async () => {
        try {
            const response = await axios.patch(`${ENDPOINTS.UPDATE_CATEGODY_API}/${id}`, body);
            dispatch(slice.actions.hasError(false));
            dispatch(slice.actions.updateCategorySuccess(response.data?.data));
            dispatch(openSnackbar({
                open: true,
                message: 'The new category has been updated successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function deleteCategory({ id, companyId }) {
    return async () => {
        try {
            const response = await axios.delete(`${ENDPOINTS.DELETE_CATEGORY_API}/${id}`);
            dispatch(slice.actions.removeCategorySuccess(id));
            dispatch(slice.actions.hasError(false));
            dispatch(openSnackbar({
                open: true,
                message: 'The selected category(ies) have been deleted successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function addService({body, categoryType}) {
    return async () => {
        try {
            const response = await axios.post(categoryType === "Product"? ENDPOINTS.UPSERT_PRODUCT :ENDPOINTS.ADD_SUB_CATEGORY_API, body);
            dispatch(slice.actions.hasError(false));
            dispatch(slice.actions.addServiceSuccess({...response.data?.data, catalogueId:body?.catalogueId}));
            dispatch(slice.actions.setExpandedCategory(body.catalogueId));
            dispatch(openSnackbar({
                open: true,
                message: 'The new item has been created successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))

        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.error || error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function updateService({ id, body ,categoryType}) {
    return async () => {
        try {
            let response;
            categoryType === "Product" ?
             response = await axios.post(ENDPOINTS.UPSERT_PRODUCT, {...body,id})
            :
             response = await axios.patch(`${ENDPOINTS.UPDATE_SUB_CATEGODY_API}/${id}`, body);

            dispatch(slice.actions.updateServiceSuccess(response.data?.data));
            dispatch(updateEmployeServices(response.data?.data));
            dispatch(slice.actions.setExpandedCategory(body.catalogueId));
            dispatch(slice.actions.hasError(false));
            dispatch(openSnackbar({
                open: true,
                message: 'The selected item has been updated successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {

            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.error || error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function deleteService({ catalogueId, id, categoryType }) {
    return async () => {
        try {
            let body;
            if (!Array.isArray(id)) {
                body = { ids: [id] }
            } else {
                body = { ids: id }
            }
            const response = await axios.patch( categoryType === "Product" ? ENDPOINTS.DELETE_PRODUCT : ENDPOINTS.DELETE_SUB_CATEGORY_API, body);
            dispatch(slice.actions.removeServiceSuccess({ catalogueId, id }));
            dispatch(slice.actions.setExpandedCategory(catalogueId));
            dispatch(slice.actions.hasError(false));
            dispatch(openSnackbar({
                open: true,
                message: 'The selected item has been deleted successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}


export function assignEmployeeToMultipleServices({ body, id }) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true));
            const response = await axios.post(`${ENDPOINTS.ASSIGN_SERVICE_TO_STAFF_API}/${id}`, body);

            dispatch(slice.actions.assignServiceSuccess({ ...response.data.data[0], serviceIds: body.empCtgyItem, catalogueId: body.catalogueId }))

            dispatch(assignServices({ ...response.data.data[0], serviceIds: body.empCtgyItem, catalogueId: body.catalogueId }))
            dispatch(slice.actions.setIsFetching(false));
            dispatch(openSnackbar({
                open: true,
                message: 'Staff selected and services assigned! Let the productivity begin. #Teamwork',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            dispatch(slice.actions.setIsFetching(false));
            dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function removeServiceEmployeeRelation({rel ,emp}){
    return async () => {
        dispatch(slice.actions.setIsFetching(true));
        try{
             const {id } = rel;
             const response = await axios.delete(`${ENDPOINTS.DELETE_STAFF_EMPLOYEE_RELATION}/${id}`);
             dispatch(slice.actions.removeServiceEmployeeRelationSuccess({...rel}));
             dispatch(removeEmployeeServiceRelation({...rel , emp}));
             dispatch(slice.actions.setIsFetching(false));
             dispatch(openSnackbar({
                open: true,
                message: 'The selected item has been removed successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }catch(error){
            dispatch(slice.actions.setIsFetching(false));
            dispatch(openSnackbar({
                open: true,
                message: error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    }
}


export function getPackage(companyId) {
    return async () => {
        try {
            dispatch(slice.actions.setIsFetching(true))
            const response = axios.get(`${ENDPOINTS.GET_PACKAGE}?companyId=${companyId}`);
            Promise.resolve(response)
            .then((results) => {
                dispatch(slice.actions.getPackageSuccess((results.data.data)))
                dispatch(slice.actions.setIsFetching(false));
            });
        }catch (error) {
            dispatch(slice.actions.setIsFetching(false))
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updatePackage(body) {
    return async () => {
        try {
            const response = await axios.post(ENDPOINTS.UPSERT_PACKAGE, body)
            if(response?.data?.success){
                if(body?.id){
                    dispatch(slice.actions.updatePackageSuccess(response.data?.data));
                }else{
                    dispatch(slice.actions.addPackageSuccess(response.data?.data));
                }
            }
            dispatch(openSnackbar({
                open: true,
                message: 'The selected item has been updated successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {

            //dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.error || error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}

export function deletePackage({id}) {
    return async () => {
        try {
            let body;
            if (!Array.isArray(id)) {
                body = { ids: [id] }
            } else {
                body = { ids: id }
            }
            const response = await axios.patch(ENDPOINTS.DELETE_PACKAGE, body);
             dispatch(slice.actions.removePackageSuccess({id }));
            dispatch(openSnackbar({
                open: true,
                message: 'The selected item has been deleted successfully.',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        } catch (error) {
            //dispatch(slice.actions.hasError(true));
            dispatch(openSnackbar({
                open: true,
                message: error.message || error.error,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                close: true
            }))
        }
    };
}
