export const ENDPOINTS = {
  LOGIN: "users/login",
  USER_API: "users/list",
  CUSTOMER_GET_APPOINTMENTS: 'users/client-list',
  SIGNUP_API: "ownerBusiness/createMerchant",
  EMAIL_VERIFICATION: "users/verify",
  COUNTRY_LIST_API: "country/list",
  ADD_CUSTOMER_API: "companies/createclient",
  ADD_CATEGORY_API: "catalogue/add",
  GET_SERVICE_API: "catalogue/list",
  ADD_SUB_CATEGORY_API: "svcctgyitems/add",
  UPDATE_CATEGODY_API: "catalogue/update",
  DELETE_CATEGORY_API: "catalogue/remove",
  UPDATE_SUB_CATEGODY_API: "svcctgyitems/update",
  UPDATE_SUB_CATEGORY_STATUS_API: "/svcctgyitems/update/status",
  UPDATE_CATEGORY_STATUS_API: "svcctgy/update/status",
  DELETE_SUB_CATEGORY_API: "svcctgyitems/remove",
  ADD_EMPLOYEE_API: "users/upsert/create-employee",
  ADD_APPOINTMENT: "appointments/upsert",
  GET_APPOINTMENT: "appointments/list",
  APPOINTMENT_TIME_BLOCK: "schedule/list",
  ASSIGN_SERVICE_TO_STAFF_API: "users/create/employee-service",
  SERVICE_STAFF_RELATION_LIST_API: "employee-service-relation/list",
  UPDATE_EMPLOYEE_STATUS: "users/status",
  DELETE_STAFF_EMPLOYEE_RELATION: "employee-service-relation/delete",
  SET_UP_BUSSINESS_API: "companies-preference/create",
  GET_BUSSINESS_SETUP_API: "companies-preference/list",
  GET_STORE_METADATA_API: "store/schedule/list",
  UPSERT_STORE_METADATA_API: "store/schedule/upsert",
  APPOINTMENT_COUNT_API: "appointments/date-filter",
  CLIENT_EMAIL_VERIFICATION: "users/verify-email",
  DELETE_EMPLOYEE: "users/remove",
  UPDATE_ATTENDENCE: "schedule/update-status",
  VERIFY_USER_REFRESH: "users/getByEmail",
  ADD_EMPLOYEE_SHIFT: "schedule/create",
  DELETE_EMPLOYEE_SHIFT: "schedule/remove",
  ADD_COMMISSION_API: "employee-commission/create",
  GET_COMMISSION_API: "employee-commission/employee-list",
  UPDATE_PASSWORD_API: "users/change-password",
  ADD_PRODUCT_API: "product/manage",
  FORGOT_PASSWORD_API:"users/forgot-password-otp",
  RESET_PASSWORD_API:"users/reset-password",
  VERIFY_OTP:"users/verify-password-otp",
  GET_MULTI_STORE_API:'ownerBusiness/store/list',
  ADD_STORE_API:'companies/management',
  EDIT_STORE_API:'companies/management',
  BUSSINESS_COUNT_API:'companies/statistics',
  ADD_STORE_LOCATION:"companies/manageAddress",
  GET_INVOICE_LIST:'company/invoice/list',
  GET_INVOICE:'company/invoice',
  ADD_INVOICE:'company/invoice/management',
  DELETE_INVOICE:'company/invoice',
  UPSERT_PRODUCT:'product/upsert',
  DELETE_PRODUCT:'product/remove',
  EDIT_PROFILE:'edit/profile',
  GET_PACKAGE:'packages/list',
  UPSERT_PACKAGE:'packages/upsert',
  DELETE_PACKAGE:'packages/delete',
  EDIT_BUSINESS_API:'ownerBusiness/createMerchant',
  BUSINESS_ADDRESS:'companies/manageAddress',
  REPORT_GET_ACCESSTOKEN:'reports/accesstoken',
  UPDATE_BUSINESS_PROFILE:'image/management',
  GET_SALES:'company/invoice/netPriceByDate'
};
