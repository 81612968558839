import { useTheme } from "@emotion/react";
import {
  Box,
  Chip,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useCompanyData } from "components/hooks/useCompanyData";
import React from "react";
import { dispatch, useAppSelector } from "store";
import {
  addService,
  deleteService,
  initializeExpandCategory,
  updateService,
} from "store/catalogue/slice";
import Loader from "ui-component/Loader";
import { capitalize, formatDuration } from "utils/general";

//third-party
import { v4 as uuid } from "uuid";

//assets
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubCard from "ui-component/cards/SubCard";
import { ThemeMode } from "config";
import PropTypes from "prop-types";
import BpCheckbox from "ui-component/extended/Form/Checkbox";
import { alpha } from "@mui/material/styles";
import CatalogueSkeleton from "ui-component/cards/Skeleton/Catalogue";
import { EnhancedTableToolbar } from "../EnhancedTableToolbar";
import { useCountyPrice } from "components/hooks/useCountyPrice";

export const RowPackages = ({ row, onClickEditData, onDeleteData }) => {
  const theme = useTheme();
  const { format } = useCountyPrice();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openServiceModal, setOpenServiceModal] = React.useState(false);
  const [editService, setEditService] = React.useState(null);
  const [deleteConfirm, setDeleteConfirm] = React.useState(null);
  const [assignConfirm, setAssignConfirm] = React.useState(false);
  const companyId = useCompanyData();
  const { serviceStaffRelationList } = useAppSelector((state) => state.staff);
  const serviceState = useAppSelector((state) => state.catalogue);
  const [selected, setSelected] = React.useState([]);

  const customChip = {
    inactive: {
      label: "Inactive",
      color: "orange.dark",
      chipcolor: alpha(theme.palette.orange.light, 0.8),
    },
    active: {
      label: "Active",
      color: "success.dark",
      chipcolor: alpha(theme.palette.success.light, 0.6),
    },
  };











  const handleClickOpen = () => {
    setOpen(!open);
    initializeExpandCategory();
  };

  // if (loading) return <Loader />;
  return (
    <>
      {serviceState.isFetching && <CatalogueSkeleton />}
      {loading && <Loader />}
      <TableRow
        hover
        sx={{ "& > *": { borderBottom: "unset", cursor: "pointer" } }}
        selected={open}
        onClick={handleClickOpen}
      >
        <TableCell sx={{ pl: 3, minWidth: 120, width: 120, height: 46 }}>
          <Stack direction="row" spacing={0.25} alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClickOpen}
            >
              {open ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowRightIcon fontSize="small" />
              )}
            </IconButton>
          </Stack>
        </TableCell>

        <TableCell component="th" scope="row">
          {capitalize(row.title)} &nbsp; ({row.packageItems?.length})
        </TableCell>
        <TableCell>
          { row.status && (
          <Chip
            label={customChip[(row?.status)?.toLowerCase()]?.label}
            size="small"
            sx={{
              bgcolor:
                theme.palette.mode === ThemeMode.DARK
                  ? "dark.main"
                  : customChip[(row?.status)?.toLowerCase()]?.chipcolor,
              color: customChip[(row?.status)?.toLowerCase()]?.color,
              cursor: "pointer",
            }}
          />
      )}
        </TableCell>
        <TableCell component="th" scope="row" align='right'>
          {format(row.grossPrice)}
        </TableCell>
        <TableCell component="th" scope="row" align='right'>
          {format(row.netPrice)}

        </TableCell>
        <TableCell align="right" sx={{ pr: 3 }}>
          <>
            <Tooltip title="Edit Category">
              <IconButton
                color="secondary"
                size="small"
                aria-label="Edit Category"
                onClick={() => {
                  onClickEditData(row);
                }}
              >
                <EditTwoToneIcon sx={{ fontSize: "1.3rem" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete Category">
              <IconButton
                color="error"
                size="small"
                aria-label="Delete"
                onClick={() => {
                  onDeleteData(row);
                }}
              >
                <DeleteTwoToneIcon sx={{ fontSize: "1.3rem" }} />
              </IconButton>
            </Tooltip>
          </>
          {/* <MdDelete fontSize={24} color='red' cursor='pointer' onClick={()=>{onDeleteData(row)}}/> */}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open || serviceState.expandedCategory === row.id}
            timeout="auto"
            unmountOnExit
          >
            {(open || serviceState.expandedCategory === row.id) && (
              <Box sx={{ margin: 1 }}>
                <SubCard
                  sx={{
                    bgcolor:
                      theme.palette.mode === ThemeMode.DARK
                        ? "dark.800"
                        : "grey.50",
                    mb: 2,
                  }}
                  title=""
                  content={false}
                >

                  <TableContainer>
                    <Table  aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          {/* <TableCell>
                            <BpCheckbox
                              color="primary"
                              indeterminate={
                                selected.length > 0 &&
                                selected.length < row?.packageItems?.length
                              }
                              checked={
                                row?.packageItems?.length > 0 &&
                                selected.length === row?.packageItems?.length
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                "aria-label": "select all desserts",
                              }}
                            />
                          </TableCell> */}
                          <TableCell>Package Name</TableCell>
                          <TableCell>Time</TableCell>
                         
                          <TableCell align="right">Price (₹)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.packageItems?.map((historyRow) => (
                          <TableRow hover key={uuid()}>
                            {/* <TableCell
                              sx={{ width: "1.0rem" }}
                              onClick={(event) =>
                                handleCheckBox(event, historyRow.id)
                              }
                            >
                              <BpCheckbox checked={isSelected(historyRow.id)} />
                            </TableCell> */}
                            <TableCell>
                              {capitalize(historyRow.title)}
                            </TableCell>
                              <TableCell>
                                {" "}
                                {formatDuration(historyRow?.duration)}
                              </TableCell>
                            
                            <TableCell align="right">
                              {historyRow.price}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SubCard>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

    </>
  );
};

RowPackages.propTypes = {
  row: PropTypes.object,
};
